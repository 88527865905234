import { create } from 'zustand';

export const useTextWidgetStore = create(() => ({
  texts: {
    cobrowse: {
      cobrowseRequest: 'Co-Browse Request',
      cobrowseStateWaiting: 'Please share your screen with me',
      cobrowseStateAccepted: 'Request accepted',
      cobrowseStateRejected: 'Request declined',
      cobrowseStateExpired: 'Request expired',
      cobrowseBtnAccept: 'Accept',
      cobrowseBtnReject: 'Decline',
      cobrowseSessionInfo: 'You are now sharing your screen',
      cobrowseControlRequest: '<b>Remote Control Request</b><br />Please let me take limited control of your screen',
      cobrowseControlInfo: '<b>Remote Control</b><br />Agent has limited control of your screen',
      cobrowseBtnStop: 'Stop Co-Browse',
      cobrowseControlGrant: 'Accept',
      cobrowseControlDecline: 'Decline',
      cobrowseControlRevoke: 'Retake control',
    },
    general: {
      welcome: 'Hello! \uD83D\uDC4B',
      welcomeLine: 'How can we help you?\n',
      ourConsultants: 'Our team is',
      chatStart: 'Chat with us',
      chatDescription: 'We are just a message away',
      knowledgeBaseStart: 'What are you looking for?',
      knowledgeBaseDescription: 'Search for a topic',
      facebookStart: 'Get in touch',
      facebookDescription: 'Keep communication in Messenger',
      whatsAppStart: 'Get in touch',
      whatsAppDescription: 'Keep communication in WhatsApp',
      audioStart: 'Call us now',
      audioDescription: 'With just a couple of clicks',
      videoStart: 'Call us now',
      videoDescription: 'With just a couple of clicks',
      callMeNowStart: 'We’ll call you',
      callMeNowDescription: 'Now or when it suits you',
      scheduleVideoStart: 'Schedule a meeting',
      scheduleVideoDescription: 'Pick a convenient date and time',
      customFormStart: 'Fill out our form',
      customFormDescription: 'We will get back to you!',
      customMeetingScheduleStart: 'Pick a convenient date and time',
      customMeetingScheduleDescription: 'Pick a convenient date and time',
      headsUpTitle: 'Hi there! \uD83D\uDC4B',
      headsUpText: 'Our team is here to help with any questions you may have. Don’t hesitate to ask!',
      groupedChannelsTitle: 'Choose your favourite chat',
      groupedChannelsSubTitle: 'Our consultants are here to help you',
      chatGroupedStart: 'Our web chat',
      facebookGroupedStart: 'Facebook Messenger',
      whatsappGroupedStart: 'WhatsApp',
      privacyUrl: '',
      privacyText:
        'Website owner is committed to protect and respect your privacy, and we’ll only use your personal info to provide the products and services requested from us.',
      privacyUrlText: 'More details',
    },
    scheduleVideo: {
      header: 'Schedule video meeting',
      leaveEmail: 'Leave your e-mail',
      orderACall: 'Request video call',
      thankYouTitle: 'Thank you for scheduling your video call!',
      thankYouDescription: 'You’ll receive a confirmation email soon with the link to join the meeting.',
      close: 'Close',
      morning: 'Morning',
      afternoon: 'Afternoon',
      evening: 'Evening',
      today: 'Today',
      tomorrow: 'Tomorrow',
    },
    chat: {
      header: 'Chat with our consultant',
      endChat: 'End chat',
      welcomeMessage: 'Let\'s chat',
      transcriptSend: 'Send transcript',
      transcriptDescription: 'Send chat transcript to your e-mail',
      transcriptSubmit: 'Submit',
      transcriptSubmitted: 'The chat transcript will be sent to',
      transcriptClose: 'Close',
      transcriptInvalidEmail: 'Please enter a valid email address',
      disconnectMessage: 'Disconnected',
      videoOfferTitle: 'Video call',
      videoOfferDescription: 'The consultant offers you a video call.',
      videoOfferCanceledText: 'Request canceled',
      videoOfferAcceptedText: 'Accepted',
      videoOfferAcceptButton: 'Join video call',
      videoOfferDeclinedText: 'Declined',
      videoOfferDeclineButton: 'Decline',
      clearHistoryDescription: 'Are you sure you want to delete your chat history?',
      clearHistoryCancel: 'Cancel',
      clearHistorySubmit: 'Delete',
      clearHistoryComplete: 'Chat history has been deleted',
      transcriptEmailInputLabel: 'E-mail address',
      transcriptComplete: 'Chat history has been sent to your email address',
      transcriptTooltip: 'Download chat history',
      clearHistoryTooltip: 'Clear chat history',
    },
    whatsApp: {
      welcomeMessage: 'Hi 👋 After sending the first message, you will be taken to WhatsApp',
    },
    messenger: {
      welcomeMessage: 'Hi 👋 After sending the first message, you will be taken to Messenger',
    },
    callMe: {
      header: 'Callback',
      whenDoYouWantUsToCall: 'When do you want us to call?',
      leaveYourPhoneToBeCalled: 'Leave your number and we’ll call you right back',
      leaveYourPhoneToBeCalledHeader: 'Call me back now',
      selectTimeToBeCalled: 'Choose date and time that suits your schedule',
      selectTimeToBeCalledHeader: 'Call me back later',
      selectTime: 'Schedule a call',
      leavePhone: 'Leave your number',
      orderACall: 'Request a call',
      thankYou: 'Thank you',
      orderConfirmation: 'orderConfirmation',
      waitingForCall: 'You will soon get a call from one of our consultants :)',
      close: 'Close',
      morning: 'Morning',
      afternoon: 'Afternoon',
      evening: 'Evening',
      today: 'Today',
      tomorrow: 'Tomorrow',
    },
    audio: {
      header: 'Call us',
      call: 'Call us',
      connect: 'Connect with our team',
      ringingAgent: 'Please wait...',
      ringingText: 'We are connecting Your call',
      thanksForCall: 'Thank you for your call',
      thanksForCallText: 'We hope that our consultant has answered all your questions',
      close: 'Close',
      callNow: 'Call us now',
      back: 'Back',
      select: 'Select',
      audioSettings: 'Audio settings',
      microphone: 'Microphone',
      speaker: 'Speaker',
      mobileSettingsLabel: 'Audio',
      disconnect: 'Disconnect',
    },
    video: {
      header: 'Call us',
      call: 'Call us',
      connect: 'Connect with our team',
      ringingAgent: 'Please wait...',
      ringingText: 'We are connecting Your call',
      consultantName: 'Anna Kowalska',
      consultantTitle: 'Consultant',
      thanksForCall: 'Thank you for your call',
      thanksForCallText: 'We hope that our consultant has answered all your questions',
      close: 'Close',
      callNow: 'Call us now',
      back: 'Back',
      select: 'Select',
      audioSettings: 'Audio settings',
      microphone: 'Microphone',
      speaker: 'Speaker',
      camera: 'Camera',
      mobileSettingsLabel: 'Audio',
      disconnect: 'Disconnect',
      chatHide: 'Hide chat',
      chatShow: 'Show chat',
      settings: 'Settings',
      fullScreen: 'Full screen',
      blurBackground: 'Blur background',
    },
    knowledgeBase: {
      search: 'Search',
      categories: 'Categories',
      articles: 'Articles',
      showingResults: 'Showing X results',
      noResultsFound: 'No results for',
    },
    preInteractionForm: {
      header: 'Leave your contact details, and we will get in touch with you.',
      firstName: 'First name',
      lastName: 'Last name',
      phoneNumber: 'Phone number',
      emailAddress: 'Email address',
      topicList: 'Topic list',
      requiredField: 'Required field',
    },
  },
}));
